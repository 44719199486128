@import "base/base";
@import "abstracts/variables";
@import "abstracts/mixins";
@import "layout/full-page";
@import "layout/left-nav";
@import "layout/nav-button";
@import "layout/item-ranking";
@import "layout/top-nav";
@import "layout/modal-popup";
@import "layout/user-menu";
@import "layout/input-forms";
@import "layout/breadcrumbs";
@import "components/user-box";
@import "components/doc-page";
@import "components/button";
@import "components/breadcrumbs";
@import "components/draggable-separator";
@import "components/state-and-district";
@import "components/action-link";
@import "pages/lottery-results";
@import "pages/security";
@import "pages/security-prev-code";
@import "pages/vertical-form";
@import "components/form";
@import "components/form-group";
@import "layout/social-media";

.ads {
  width: 225px;
  background-color: $color-primary-4;
}

.required-fields-note {
  font-size: 75%;
}

.profile {
  width: 100%;
}

::placeholder {
  color: rgb(206, 206, 206);
}
