:root {
  --color-primary: #eb2f64;
  --color-primary-light: #f36;
  --color-primary-dark: #ba265d;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --shadow-dark: 0 2rem 6rem #0000004d;
  --shadow-light: 0 2rem 5rem #0000000f;
  --line: 1px solid var(--color-grey-light-2);
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  text-rendering: optimizeLegibility;
  background-color: #f8f9fa;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  word-spacing: .3rem;
  color: #000;
  font-family: Lato, Arial, sans-serif;
  font-weight: 300;
}

input:focus, select:focus, textarea:focus, button:focus {
  outline: none;
}

a:link, a:visited {
  color: inherit;
  text-decoration: none;
}

.loader--hidden {
  visibility: hidden;
}

.loader {
  z-index: 13;
  background-color: #0009;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loader:after {
  content: "";
  z-index: 14;
  border: 15px solid #ddd;
  border-top-color: #0466c8;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: .75s infinite loading;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.loader-main {
  z-index: 13;
  background-color: #e6e6e6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loader-main:after {
  content: "";
  z-index: 14;
  border: 15px solid #ddd;
  border-top-color: #0466c8;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: .75s infinite loading-view;
}

@keyframes loading-view {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.entire-viewport {
  background-color: #5c677d;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.top-container {
  flex-direction: column;
  width: 100%;
  max-width: 110rem;
  display: flex;
  position: relative;
}

.page-wrapper {
  overflow: hidden;
}

.fixed {
  z-index: 1;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 5px gray;
}

.header {
  color: #d4d4d4;
  background-image: linear-gradient(to top right, #5c677d, #001233);
  border-bottom: 3px solid #0353a4;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.scroll {
  overflow: auto;
}

.body-area {
  z-index: 0;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  box-shadow: 0 0 5px gray;
}

.left-nav {
  width: 35%;
}

@media only screen and (width <= 685px) {
  .left-nav {
    width: 100%;
  }
}

.separator {
  width: 9px;
}

@media only screen and (width <= 685px) {
  .separator {
    display: none;
  }
}

.main-content-area-container {
  background-color: #e6e6e6;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  padding-bottom: 10rem;
  font-size: 1.6rem;
  display: flex;
  position: relative;
}

.main-content-area-container h2 {
  font-size: 2.5rem;
}

.main-content-area-container p:not(:last-of-type) {
  margin-bottom: 2rem;
}

@media only screen and (width <= 685px) {
  .main-content-area-container {
    width: 0%;
  }
}

.main-content-area {
  flex-grow: 1;
  width: 100%;
}

.left-nav {
  color: #d4d4d4;
  white-space: nowrap;
  background-image: linear-gradient(to bottom left, #5c677d, #001233);
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  font-size: 1.8rem;
  display: flex;
}

.left-nav ul {
  margin-left: 1em;
}

.tree-container ul {
  line-height: 2em;
  list-style: none;
}

.tree-container ul li {
  position: relative;
}

.tree-container ul li:before {
  content: "";
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  width: 8px;
  height: 1em;
  position: absolute;
  top: 0;
  left: -10px;
}

.tree-container ul li:after {
  content: "";
  border-left: 1px solid gray;
  width: 8px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: -10px;
}

.tree-container ul li:last-child:after {
  display: none;
}

.tree-container ul summary {
  cursor: pointer;
}

.tree-container ul summary::marker {
  display: none;
}

.tree-container ul summary::-webkit-details-marker {
  display: none;
}

.tree-container ul summary::marker {
  content: "";
}

.tree-container ul summary::-webkit-details-marker {
  content: "";
}

.tree-container ul summary:before {
  color: #fff;
  content: "+";
  z-index: 10;
  text-align: center;
  background: gray;
  border-radius: 20px;
  width: 15px;
  height: 15px;
  line-height: 12px;
  display: block;
  position: absolute;
  top: 12px;
  left: -18px;
}

.tree-container ul details[open] > summary:before {
  content: "-";
}

.tree-container left-nav-button {
  vertical-align: middle;
}

.nav-selected {
  color: #000;
  background-color: #d4d4d4;
}

ul.tree > li:after, ul.tree > li:before {
  display: none;
}

.footer-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 685px) {
  .footer-container {
    padding-bottom: 10rem;
  }
}

.top-separator {
  border-top: 1px solid #5c677d;
  width: 100%;
  margin: 3rem 3rem 2rem 2rem;
}

.footer-page-links {
  margin-top: 1.5rem;
}

.footer-page-links a {
  font-size: 1.5rem;
}

.footer-page-links a:not(:last-child) {
  margin-right: 2rem;
}

.footer-page-links a:link, .footer-page-links a:visited {
  color: #d4d4d4;
  text-decoration: none;
}

.footer-page-links a:hover {
  border-bottom: 2px solid #0466c8;
}

.ddf-footer {
  color: #7d8597;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  display: flex;
}

.nav-btn {
  display: none;
}

@media only screen and (width <= 685px) {
  .nav-btn {
    display: inline-block;
  }

  .nav-btn__checkbox {
    display: none;
  }

  .nav-btn__button {
    text-align: center;
    z-index: 1000;
    background-color: #fff;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    position: fixed;
    bottom: 3rem;
    left: 50%;
    translate: -50%;
    box-shadow: 0 1rem 3rem #0000001a;
  }

  .nav-btn__icon {
    margin-top: 2.3rem;
    position: relative;
  }

  .nav-btn__icon, .nav-btn__icon:before, .nav-btn__icon:after {
    background-color: #002855;
    width: 3rem;
    height: 3px;
    display: inline-block;
  }

  .nav-btn__icon:before, .nav-btn__icon:after {
    content: "";
    transition: all .2s;
    position: absolute;
    left: 0;
  }

  .nav-btn__icon:before {
    top: -.8rem;
  }

  .nav-btn__icon:after {
    top: .8rem;
  }

  .nav-btn__checkbox.is-active + .nav-btn__button .nav-btn__icon {
    background-color: #0000;
  }

  .nav-btn__checkbox.is-active + .nav-btn__button .nav-btn__icon:before {
    top: 0;
    transform: rotate(45deg);
  }

  .nav-btn__checkbox.is-active + .nav-btn__button .nav-btn__icon:after {
    top: 0;
    transform: rotate(-45deg);
  }
}

.rank-edit-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.rank-edit-container h1 {
  text-align: center;
  margin-top: 1rem;
}

.rank-edit-controls {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.rank-edit-controls label {
  margin-right: 5px;
}

.rank-edit__dropdown {
  flex-direction: row;
  display: flex;
}

.drop-area-content {
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.yes-change {
  background-color: #2fea2f;
  flex-direction: column;
  flex-grow: .33;
  min-height: 5rem;
  display: flex;
}

.yes-change.drag-over-empty {
  border: 7px dashed green;
}

.undecided {
  background-color: #f2f246;
  flex-direction: column;
  flex-grow: .33;
  min-height: 5rem;
  display: flex;
}

.undecided.drag-over-empty {
  border: 7px dashed green;
}

.no-do-not-change {
  background-color: #e45858;
  flex-direction: column;
  flex-grow: .33;
  min-height: 5rem;
  display: flex;
}

.no-do-not-change.drag-over-empty {
  border: 7px dashed green;
}

.ranked-item {
  white-space: nowrap;
  background-color: #e9ecef;
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  margin: 20px 30px 0;
  padding: 10px 5px 3px;
  font-weight: 500;
  display: flex;
  overflow: hidden;
  box-shadow: 0 5px 5px gray;
}

.ranked-item:last-child {
  margin-bottom: 2rem;
}

.ranked-item p {
  flex-shrink: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0;
}

.ranked-item p:hover {
  color: #0466c8;
  cursor: pointer;
  border-bottom: 2px solid #0466c8;
}

.ranked-item-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1rem;
  overflow: hidden;
}

.ranked-item-button-bar {
  flex-direction: row;
  padding-left: 1rem;
  font-size: 1rem;
  display: flex;
}

.ranked-item-button-abstract, .ranked-item-button-stats {
  fill: #33415c;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 8px;
}

.ranked-item-button:hover {
  cursor: pointer;
  color: #0466c8;
}

.ranked-item-bottom-space {
  height: 20px;
}

.pts-box-pts {
  color: #7d8597;
  font-size: 1rem;
}

.ranked-item.dragging {
  opacity: .5;
}

.ranked-item.closeBelow {
  border-top: 7px dashed green;
}

.ranked-item.closeAbove {
  border-bottom: 7px dashed green;
}

.grabbable {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/openhand.cur"), all-scroll;
}

.logo {
  font-size: 4rem;
}

@media only screen and (width <= 685px) {
  .logo {
    font-size: 1.8rem;
  }
}

.logo-bottom {
  text-align: center;
  border-top: 2px solid #d4d4d4;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  font-size: 2rem;
  display: flex;
}

@media only screen and (width <= 685px) {
  .logo-bottom {
    border-top: 1px solid #d4d4d4;
    font-size: .9rem;
  }
}

.logo-area {
  white-space: nowrap;
  flex-direction: column;
  padding-left: 2rem;
  display: flex;
}

@media only screen and (width <= 685px) {
  .logo-area {
    padding-left: .5rem;
  }
}

.logo-area h1 {
  font-weight: 300;
}

.main-nav-box {
  display: block;
}

.main-nav {
  justify-content: space-between;
  font-size: 2rem;
  display: flex;
}

@media only screen and (width <= 685px) {
  .main-nav {
    font-size: 1.5rem;
  }
}

.main-nav li {
  display: inline-block;
}

.main-nav li:first-child {
  margin-right: 3rem;
}

@media only screen and (width <= 685px) {
  .main-nav li:first-child {
    margin-right: 2rem;
  }
}

.main-nav li:last-child {
  margin-left: 3rem;
}

@media only screen and (width <= 685px) {
  .main-nav li:last-child {
    display: none;
  }
}

.main-nav-link {
  cursor: pointer;
  color: #d4d4d4;
  border-bottom: 2px solid #0000;
  margin: .4rem 0;
  padding: .4rem 0;
  text-decoration: none;
  transition: border-bottom .2s;
}

.main-nav-link:hover {
  color: #d4d4d4;
  border-bottom: 2px solid #0466c8;
  margin: .4rem 0;
  padding: .4rem 0;
  text-decoration: none;
}

.curr-nav {
  border-bottom: 2px solid #0466c8;
}

.user-area {
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
}

@media only screen and (width <= 685px) {
  .user-area {
    padding-right: .5rem;
  }
}

.user-name {
  text-align: center;
}

@media only screen and (width <= 685px) {
  .user-name {
    padding-bottom: .4rem;
  }
}

.user-icon {
  fill: #d4d4d4;
  width: 3rem;
  height: 3rem;
}

@media only screen and (width <= 685px) {
  .user-icon {
    padding-top: .5rem;
  }
}

.hidden {
  display: none;
}

.modal {
  z-index: 12;
  background-color: #e9ecef;
  border-radius: 5px;
  width: 70%;
  max-width: 50rem;
  padding: 2rem;
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 3rem 5rem #0000004d;
}

@media only screen and (width <= 685px) {
  .modal {
    width: 100%;
  }
}

.modal h1 {
  margin-bottom: 1.5rem;
  font-size: 3rem;
}

.modal p {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
}

.overlay {
  z-index: 11;
  background-color: #0009;
  width: 100%;
  height: 500%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (height >= 900px) {
  .overlay {
    backdrop-filter: blur(3px);
    background-color: #0009;
  }
}

.user-menu {
  z-index: 4;
  background-color: #fff;
  display: none;
  position: absolute;
  top: 50px;
  right: 50px;
  box-shadow: 0 4px 5px 3px #0003;
}

.user-menu__options {
  padding: 10px 0;
  list-style: none;
}

.user-menu__option {
  cursor: pointer;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 2rem 3rem 2rem 2rem;
  font-size: 2rem;
  font-weight: 500;
  display: flex;
}

.user-menu__option:hover > svg *, .user-menu__option:hover {
  color: #fff;
  fill: #fff;
  background-color: #0466c8;
}

.user-menu__icon {
  fill: #0466c8;
  justify-content: center;
  align-items: center;
  width: 3.8rem;
  height: 3.8rem;
  padding-right: 2rem;
}

label {
  margin-top: 15px;
  font-size: 1em;
  display: block;
}

input {
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 2rem;
}

.breadcrumbs {
  white-space: nowrap;
  padding: .5rem;
  overflow-x: auto;
}

.breadcrumbs .bc-separator, .breadcrumbs .bc-leaf {
  font-weight: 400;
}

.breadcrumbs a:link, .breadcrumbs a:visited {
  color: #0466c8;
  font-weight: 400;
  text-decoration: none;
}

.breadcrumbs-container {
  position: relative;
}

.user-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 7rem;
  font-size: 1rem;
  overflow: hidden;
}

.doc-page {
  background-color: #0000;
  width: 100%;
  max-width: 80rem;
  padding: 3rem;
  font-weight: 400;
  line-height: 2.3rem;
}

@media only screen and (width <= 685px) {
  .doc-page {
    padding: 2.5rem 1rem 1rem;
    font-size: 1.2em;
    line-height: 1.5em;
  }
}

.doc-page h1 {
  text-align: center;
  color: #0353a4;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  line-height: 5rem;
}

.doc-page h2 {
  text-align: left;
  color: #023e7d;
  margin-bottom: 3rem;
  font-size: 2.2rem;
  line-height: 5rem;
}

.doc-page ul, .doc-page ol {
  margin-bottom: 2rem;
  margin-left: 3rem;
  padding-left: 2rem;
}

.doc-page a:link, .doc-page a:visited {
  color: #0466c8;
  text-decoration: none;
}

.doc-page a:hover {
  border-bottom: 2px solid #0466c8;
}

.doc-page .footer {
  margin-top: 3rem;
}

.doc-page .footer p {
  color: #7d8597;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
  display: flex;
}

.btn {
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #0466c8;
  border: none;
  border-radius: 2rem;
  padding: 1rem;
  font-size: 1.6rem;
  transition: background-color .2s;
  display: inline-block;
}

.btn-save {
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn:not(:last-of-type) {
  margin-right: 2rem;
}

.btn:hover {
  background-color: #0353a4;
}

.btn:disabled {
  color: #5c677d;
  cursor: not-allowed;
  background-color: #979dac;
  border: none;
}

.btn-ghost {
  color: #0466c8;
  background-color: #fff;
  border: 1px solid #0466c8;
}

.btn-ghost:not(:disabled):hover {
  color: #fff;
}

.prof-submit-btn {
  text-align: center;
  margin-top: 2rem;
}

.breadcrumbs {
  background-color: #e9ecef;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  display: flex;
}

.bc-home-anchor {
  border-bottom: 2px solid #0000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bc-home-icon {
  fill: #0466c8;
  width: 2rem;
  height: 1.5rem;
}

.bc-home-text {
  color: #0466c8;
  font-size: 1rem;
}

.separator {
  cursor: col-resize;
  -webkit-user-select: none;
  user-select: none;
  background-color: #d3d3d3;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='30'><path d='M2 0 v30 M5 0 v30 M8 0 v30' fill='none' stroke='black'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
}

.state-and-district {
  flex-direction: row;
  justify-content: left;
  display: flex;
}

.state-and-district__state {
  flex-basis: 60%;
}

.state-and-district__district {
  flex-basis: 40%;
}

.action-link {
  color: #023e7d;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 2px solid #0000;
  padding-bottom: 2px;
  font-size: 1.5rem;
  font-weight: 400;
  transition: all .2s;
}

.action-link:hover {
  color: #0466c8;
  border-bottom: 2px solid #0466c8;
}

.lottery-ui label {
  padding-left: 1.5em;
}

.lottery-ui label:first-of-type {
  padding-left: 0;
}

.lottery-ui h3 {
  padding-top: 2em;
}

.lottery-ui ol {
  padding-top: 1em;
  padding-left: 2em;
}

.lottery-inputs {
  padding-top: 2em;
}

.lottery-inputs label {
  margin-top: 1em;
  display: inline;
}

.lottery-inputs button {
  margin-top: 2em;
  display: inline;
}

.security {
  grid-template-rows: 5rem repeat(6, 4rem);
  grid-template-columns: repeat(2, minmax(min-content, 20rem));
  place-items: center right;
  margin: 0 auto;
  display: grid;
}

.security__title {
  grid-column: 1 / 3;
  justify-self: center;
  margin-bottom: 2rem;
}

.security__info {
  grid-column: 1 / 3;
  place-self: start;
  font-size: 1.3rem;
  font-weight: 300;
}

.security__code {
  grid-area: 4 / 1 / 5 / 3;
  justify-self: start;
}

.security__status {
  color: red;
  grid-area: 5 / 1 / 6 / 3;
  justify-self: center;
  font-size: 1.3rem;
}

.security__target-label {
  grid-row: 4 / 5;
  margin-right: 3rem;
}

.security__target-value {
  grid-row: 4 / 5;
  justify-self: start;
}

.security__method-label {
  grid-row: 5 / 6;
  margin-right: 3rem;
}

.security__method-value {
  grid-row: 5 / 6;
  justify-self: start;
}

.security__prev-code-arrived, .security__try-another-way {
  grid-area: 6 / 1 / 7 / 3;
  justify-self: start;
}

.security__next {
  grid-area: 7 / 2 / 8 / 3;
}

.security-prev-code {
  grid-template-rows: 5rem repeat(6, 4rem);
  grid-template-columns: repeat(2, minmax(min-content, 20rem));
  place-items: center right;
  margin: 0 auto;
  display: grid;
}

.security-prev-code__title {
  grid-column: 1 / 3;
  justify-self: center;
  margin-bottom: 2rem;
}

.security-prev-code__info {
  grid-column: 1 / 3;
  place-self: start;
  font-size: 1.3rem;
  font-weight: 300;
}

.security-prev-code__target-label {
  grid-area: 3 / 1 / 4 / 2;
  justify-self: end;
  margin-right: 3rem;
}

.security-prev-code__target-value {
  grid-area: 3 / 2 / 4 / 3;
  justify-self: start;
}

.security-prev-code__code-label {
  grid-area: 4 / 1 / 5 / 2;
  justify-self: end;
  margin-right: 3rem;
}

.security-prev-code__code-previous {
  grid-area: 4 / 2 / 5 / 3;
  place-self: start;
}

.security-prev-code__status {
  color: red;
  grid-area: 5 / 1 / 6 / 3;
  justify-self: center;
  font-size: 1.3rem;
}

.security-prev-code__try-another-way {
  grid-area: 6 / 1 / 7 / 3;
  justify-self: start;
}

.security-prev-code__next {
  grid-area: 7 / 2 / 8 / 3;
}

.vertical-form {
  grid-template-columns: 40rem;
  place-items: center stretch;
  margin: 0 auto;
  display: grid;
}

.form {
  justify-content: center;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 3rem;
  font-weight: 400;
  display: flex;
}

@media only screen and (width <= 685px) {
  .form {
    padding: 1rem;
    font-size: 1.2em;
    line-height: 1rem;
  }
}

.form h1 {
  text-align: center;
  color: #0353a4;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  line-height: 4rem;
}

.form__group {
  margin-bottom: 2rem;
}

.form__forgot-pwd {
  flex-direction: row;
  justify-content: left;
  margin-top: 2rem;
  display: flex;
}

.form__status {
  color: green;
  height: 1.5em;
  margin-bottom: 1em;
  font-size: .7em;
}

.form__status .invalid-code {
  color: red;
}

.form__statusbottom {
  color: green;
  border-bottom: 2px solid #0466c8;
  height: 1.5em;
  margin-bottom: 3em;
  padding-bottom: 2em;
  font-size: .7em;
}

.form__statusbottom .invalid-code {
  color: red;
}

.form__input {
  color: inherit;
  background-color: #ffffff80;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: 2px;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2rem;
  font-family: inherit;
  font-size: 1.5rem;
  transition: all .3s;
  display: block;
}

.form__input:focus {
  border-bottom: 3px solid #0466c8;
  outline: none;
  box-shadow: 0 1rem 2rem #0000001a;
}

.form__input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}

.form__input::-webkit-input-placeholder {
  color: #979dac;
}

.form__input:disabled {
  color: #5c677d;
  cursor: not-allowed;
  background-color: #979dac;
  border: none;
}

.form__input:disabled::placeholder {
  color: #5c677d;
}

.form__phone-status {
  color: red;
  height: 1.5em;
  font-size: .7em;
}

.form__phone-status .hidden {
  display: none;
}

.form__label {
  color: #979dac;
  margin: .7rem 0 0 2rem;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all .3s;
  display: block;
}

.form__label .hidden {
  display: none;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.form select {
  box-shadow: none;
  background: #ffffff80;
  border-radius: 2px;
  outline: 0;
  margin-bottom: .5em;
  padding: .4rem 1rem;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: 400;
  border: 0 !important;
}

.form fieldset {
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: inherit;
  border: 1px solid #979dac;
  border-radius: 5px;
  width: 90%;
  padding: 1rem 3rem;
}

.form__input-check {
  margin: 0;
}

.form-group {
  grid-row-gap: .5rem;
  grid-template-rows: 4rem 2rem;
  grid-template-columns: 1fr 1fr 1px;
  align-items: center;
  margin-bottom: 5px;
  display: grid;
}

.form-group__input {
  height: 3rem;
  color: inherit;
  background-color: #ffffff80;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: 2px;
  grid-column: 1 / 3;
  width: 100%;
  padding: 1.5rem 2rem;
  font-family: inherit;
  font-size: 1.5rem;
  transition: all .3s;
  display: block;
}

.form-group__input::-webkit-input-placeholder {
  color: #979dac;
}

.form-group__input-notvalid {
  border-bottom: 3px solid red;
}

.form-group__label {
  color: #979dac;
  grid-column: 1 / 2;
  margin: 0 0 0 2rem;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all .3s;
}

.form-group__label .hidden {
  visibility: hidden;
}

.form-group__input:placeholder-shown + .form-group__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.form-group__error-msg {
  color: red;
  grid-column: 2 / 4;
  font-size: 1.2rem;
}

.form-group__error-msg .hidden {
  visibility: hidden;
}

.form-group__error-icon {
  fill: red;
  grid-area: 1 / 3 / 2 / 4;
  width: 1.5rem;
  height: 1.5rem;
  translate: -1.8rem;
}

.form-group__error-icon .hidden {
  visibility: hidden;
}

.form-group__error-icon__select {
  translate: -3rem;
}

.form-group__container .hidden {
  visibility: hidden;
}

.social-share {
  z-index: 2;
  background-color: #fff;
  border-radius: .5rem;
  display: block;
  position: absolute;
  top: 4.5rem;
  right: 5px;
  box-shadow: 0 4px 5px 3px #0003;
}

.social-share ul {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.social-share__options {
  cursor: pointer;
  padding: 10px 0;
  list-style: none;
}

.social-share__icon {
  fill: #0466c8;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin: .7rem 1rem;
}

.social-share__icon .hidden {
  display: none;
}

.social-share__option {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 686px) {
  .social-share__option:hover > svg *, .social-share__option:hover {
    fill: #fff;
    background-color: #0466c8;
    border-radius: .5rem;
  }
}

.ads {
  background-color: #002855;
  width: 225px;
}

.required-fields-note {
  font-size: 75%;
}

.profile {
  width: 100%;
}

::placeholder {
  color: #cecece;
}
/*# sourceMappingURL=index.70b704e3.css.map */
