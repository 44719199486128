.rank-edit {

  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    & h1 {
      text-align: center;
      margin-top: 1rem;
    }
  }

  &-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
    & label {
      margin-right: 5px;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: row;
  }
  
}

.drop-area-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.yes-change {
  display: flex;
  flex-direction: column;
  flex-grow: 0.33;
  background-color: rgb(47, 234, 47);
  min-height: 5rem;
}

.yes-change.drag-over-empty {
  border-style: dashed;
  border-color: green;
  border-width: 7px;
}

.undecided {
  display: flex;
  flex-direction: column;
  flex-grow: 0.33;
  background-color: rgb(242, 242, 70);
  min-height: 5rem;
}

.undecided.drag-over-empty {
  border-style: dashed;
  border-color: green;
  border-width: 7px;
}

.no-do-not-change {
  display: flex;
  flex-direction: column;
  flex-grow: 0.33;
  background-color: rgb(228, 88, 88);
  min-height: 5rem;
}

.no-do-not-change.drag-over-empty {
  border-style: dashed;
  border-color: green;
  border-width: 7px;
}

.ranked-item {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  margin: 20px 30px 0px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 5px grey;
  padding: 10px 5px 3px 5px;
  background-color: $color-white-1;
  white-space: nowrap;
  overflow: hidden;

  &:last-child {
    margin-bottom: 2rem;
  }

  & p {
    flex-shrink: 1;
    padding: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  & p:hover {
    color: $color-primary-1;
    cursor: pointer;
    border-bottom: 2px solid $color-primary-1;
  }

  &-title {
    padding-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-button-bar {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    padding-left: 1rem;
  }

  &-button-abstract,
  &-button-stats {
    height: 1.6rem;
    width: 1.6rem;
    fill: $color-grey-4;
    margin-right: 8px;
    cursor: pointer;
  }

  &-button:hover {
    cursor: pointer;
    color: $color-primary-1;
  }

  &-bottom-space {
    height: 20px;
  }
}

.pts-box-pts {
  font-size: 1rem;
  color: $color-grey-2;
}

.ranked-item.dragging {
  opacity: 0.5;
}

.ranked-item.closeBelow {
  border-top-color: green;
  border-top-style: dashed;
  border-top-width: 7px;
}

.ranked-item.closeAbove {
  border-bottom-color: green;
  border-bottom-style: dashed;
  border-bottom-width: 7px;
}

.grabbable {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/openhand.cur"),
    all-scroll;
}
