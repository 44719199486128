@import "../abstracts/variables";
@import "../abstracts/mixins";

:root {
    --color-primary: #eb2f64;
    --color-primary-light: #FF3366;
    --color-primary-dark: #BA265D;
    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f4f2f2;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;

    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;

    --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, .3);
    --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);

    --line: 1px solid var(--color-grey-light-2);
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
  
html {
    font-size: 62.5%;
    text-rendering: optimizeLegibility;
    background-color: #f8f9fa;

    // @include respond(phone) {
    //     font-size: 50%;
    // }
}

body {
    font-family: "Lato", "Arial", sans-serif;
    box-sizing: border-box;
    font-weight: 300;
    word-spacing: .3rem;
    color: $color-black;
}
  
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

a:link,
a:visited {
    text-decoration: none;
    color: inherit;
}

.loader--hidden {
    visibility: hidden;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 13;

    &::after {
        content: "";
        width: 75px;
        height: 75px;
        border: 15px solid #dddddd;
        border-top-color: $color-primary-1;
        border-radius: 50%;
        animation: loading 0.75s ease infinite;
        z-index: 14;
    }

    @keyframes loading {
        from {
            transform: rotate(0turn);
        }
        to {
            transform: rotate(1turn);
        }
    }
}

.loader-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white-2;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 13;

    &::after {
        content: "";
        width: 75px;
        height: 75px;
        border: 15px solid #dddddd;
        border-top-color: $color-primary-1;
        border-radius: 50%;
        animation: loading-view 0.75s ease infinite;
        z-index: 14;
    }

    @keyframes loading-view {
        from {
            transform: rotate(0turn);
        }
        to {
            transform: rotate(1turn);
        }
    }
}