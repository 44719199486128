.lottery-ui label {
    padding-left: 1.5em;
  }
  
  .lottery-ui label:first-of-type {
    padding-left: 0;
  }
  
  .lottery-ui h3 {
    padding-top: 2em;
  }
  
  .lottery-ui ol {
    padding-top: 1em;
    padding-left: 2em;
  }
  
  .lottery-inputs {
    padding-top: 2em;
  }
  
  .lottery-inputs label {
    display: inline;
    margin-top: 1em;
  }
  
  .lottery-inputs button {
    display: inline;
    margin-top: 2em;
  }