.user-menu {
    // width: 120px;
    box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: absolute;
    top: 50px;
    right: 50px;
    display: none;
    z-index: 4;

    &__options {
        list-style: none;
        padding: 10px 0;
    }

    &__option {
        font-weight: 500;
        font-size: 2rem;
        padding: 2rem 3rem 2rem 2rem;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
    
        &:hover > svg *,
        &:hover {
            background-color: $color-primary-1;
            color: #fff;
            fill: #fff;
          }
    }

    &__icon {
        height: 3.8rem;
        width: 3.8rem;
        padding-right: 2rem;
        fill: $color-primary-1;
        align-items: center;
        justify-content: center;
    }
}