.entire-viewport {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: $color-grey-3;
}

.top-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 110rem;
}

.page-wrapper {
  overflow: hidden;
}

.fixed {
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px grey;
}

.header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid $color-primary-2;
  color: rgb(212, 212, 212);
  background-image: linear-gradient(
    to top right,
    $color-grey-3,
    $color-primary-6
  );
}

.scroll {
  overflow-x: auto;
  overflow-y: auto;
}

.body-area {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  z-index: 0;
  box-shadow: 0 0 5px grey;
}

.left-nav {
  width: 35%;

  @include respond(phone) {
    width: 100%;
  }

}

.separator {
  width: 9px;

  @include respond(phone) {
    display: none;
  }
}

.main-content-area-container {
  position: relative;
  width: 60%;
  flex-grow: 1;
  font-size: 1.6rem;
  background-color: $color-white-2;
  padding-bottom: 10rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  & h2 {
    font-size: 2.5rem;
  }

  & p:not(:last-of-type) {
      margin-bottom: 2rem;
  }

  @include respond(phone) {
    width: 0%;
  }

}

.main-content-area {
  width: 100%;
  flex-grow: 1;
}