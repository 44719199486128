.security-prev-code {
    margin: 0 auto;
    display: grid;
    grid-template-rows: 5rem repeat(6, 4rem);
    grid-template-columns: repeat(2, minmax(min-content, 20rem));
    justify-items: right;
    align-items: center;

    &__title {
        grid-column: 1 / 3;
        justify-self: center;
        margin-bottom: 2rem;
    }

    &__info {
        grid-column: 1 / 3;
        justify-self: start;
        align-self: start;
        font-size: 1.3rem;
        font-weight: 300;
    }

    &__target-label {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        justify-self: end;
        margin-right: 3rem;
    }

    &__target-value {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        justify-self: start;
    }

    &__code-label {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
        justify-self: end;
        margin-right: 3rem;
    }

    &__code-previous {
        grid-column: 2 / 3;
        grid-row: 4 / 5;
        justify-self: start;
        align-self: start;
    }

    &__status {
        grid-column: 1 / 3;
        grid-row: 5 / 6;
        justify-self: center;
        font-size: 1.3rem;
        color: red;
    }

    &__try-another-way {
        grid-row: 6 / 7;
        grid-column: 1 / 3;
        justify-self: start;
    }

    &__next {
        grid-column: 2 / 3;
        grid-row: 7 / 8;
    }
}