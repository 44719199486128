.logo {
    font-size: 4rem;

    @include respond(phone) {
        font-size: 1.8rem;
    }

    &-bottom {
        font-size: 2rem;
        width: 100%;
        text-align: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        border-top: 2px solid $color-logo-grey;

        @include respond(phone) {
            font-size: .9rem;
            border-top: 1px solid $color-logo-grey;
        }
    }
    
    &-area {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        white-space: nowrap;

        @include respond(phone) {
            padding-left: .5rem;
        }
    
        h1 {
            font-weight: 300;
        }
    }
}

.main-nav-box {
    display: block;
}

.main-nav {
    font-size: 2rem;
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
        font-size: 1.5rem;
    }
 
    & li {
        display: inline-block;
    }

    & li:first-child {
        margin-right: 3rem;

        @include respond(phone) {
            margin-right: 2rem;
        }
    }

    & li:last-child {
        margin-left: 3rem;
        
        @include respond(phone) {
            display: none;
        }
    }

    &-link {
        cursor: pointer;
        padding: .4rem 0;
        margin: .4rem 0;
        text-decoration: none;
        color: $color-logo-grey;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.2s;
    }

    &-link:hover {
        padding: .4rem 0;
        margin: .4rem 0;
        text-decoration: none;
        color: $color-logo-grey;
        border-bottom: 2px solid $color-primary-1;
    }

}

.curr-nav {
    border-bottom: 2px solid $color-primary-1;
}

.user-area {
    text-align: center;
    margin-bottom: 0;
    cursor: pointer;

    @include respond(phone) {
        padding-right: .5rem;
    }
}

.user-name {
    text-align: center;

    @include respond(phone) {
        padding-bottom: .4rem;
    }
}

.user-icon {
    fill: $color-logo-grey;
    height: 3rem;
    width: 3rem;

    @include respond(phone) {
        padding-top: .5rem;
    }
}
  