.nav-btn {
    display: none;
    
    @include respond(phone) {
        display: inline-block;

        &__checkbox {
            display: none;
        }

        &__button {
            background-color: $color-white;
            height: 5rem;
            width: 5rem;
            position: fixed;
            text-align: center;
            bottom: 3rem;
            left: 50%;
            translate: -50%;
            border-radius: 50%;
            z-index: 1000;
            box-shadow: 0 1rem 3rem rgba($color-black, .1);
        }

        &__icon {
            position: relative;
            margin-top: 2.3rem;

            &,
            &::before,
            &::after {
                width: 3rem;
                height: 3px;
                background-color: $color-primary-4;
                display: inline-block;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                transition: all .2s;
            }

            &::before { top: -.8rem; }
            &::after { top: .8rem; }
        }

        &__checkbox.is-active + &__button &__icon {
            background-color: transparent;
        }

        &__checkbox.is-active + &__button &__icon::before {
            top: 0;
            transform: rotate(45deg);
        }

        &__checkbox.is-active + &__button &__icon::after {
            top: 0;
            transform: rotate(-45deg);
        }
    }
}