.form-group {
    display: grid;
    grid-template-rows: 4rem 2rem;
    grid-template-columns: 1fr 1fr 1px;
    grid-row-gap: .5rem;
    align-items: center;
    margin-bottom: 5px;

    &__input {
        height: 3rem;
        grid-column-start: 1;
        grid-column-end: 3;
        font-size: 1.5rem;
        font-family: inherit;
        color: inherit;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: rgba($color-white, .5);
        border: none;
        border-bottom: 3px solid transparent;
        width: 100%;
        display: block;
        transition: all .3s;

        &::-webkit-input-placeholder {
            color: $color-grey-1;
        }
    }

    &__input-notvalid {
        border-bottom: 3px solid red;
    }

    &__label {
        margin: 0 0 0 2rem;
        padding: 0 0 0 0;
        color: $color-grey-1;
        font-size: 1.2rem;
        font-weight: 700;
        transition: all .3s;

        & .hidden {
            visibility: hidden;
        }

        grid-column-start: 1;
        grid-column-end: 2;
    }

    &__input:placeholder-shown+&__label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    &__error-msg {
        grid-column-start: 2;
        grid-column-end: 4;
        font-size: 1.2rem;
        color: red;

        & .hidden {
            visibility: hidden;
        }
    }

    &__error-icon {
        height: 1.5rem;
        width: 1.5rem;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
        translate: -1.8rem;
        fill: red;

        & .hidden {
            visibility: hidden;
        }
    }

    &__error-icon__select {
        translate: -3rem;
    }

    &__container {
        & .hidden {
            visibility: hidden;
        }
    }
}