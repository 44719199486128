label {
    font-size: 1em;
    display: block;
    margin-top: 15px;
}
  
input {
  border-radius: 3px;
  border: 1px solid #ccc;
  height: 2rem;
}