.btn {
  display: inline-block;
  background-color: $color-primary-1;
  color: #fff;
  font-size: 1.6rem;
  padding: 1rem;
  border-radius: 2rem;
  border: none;
  transition: background-color 0.2s;
  box-sizing: border-box;
  cursor: pointer;

  &-save {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &:not(:last-of-type) {
    margin-right: 2rem;
  }

  &:hover {
    background-color: $color-primary-2;
  }

  &:disabled {
    background-color: $color-grey-1;
    color: $color-grey-3;
    cursor: not-allowed;
    border: none;
  }
}

.btn-ghost {
  border: 1px solid $color-primary-1;
  background-color: white;
  color: $color-primary-1;

  &:not(:disabled):hover {
    color: white;
  }
}

.prof-submit-btn {
  text-align: center;
  margin-top: 2rem;
}