.form {
    width: 100%;
    max-width: 60rem;
    padding: 3rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @include respond(phone) {
        padding: 1rem;
        font-size: 1.2em;
        line-height: 1rem;
    }

    & h1 {
        font-size: 2.5rem;
        line-height: 4rem;
        margin-bottom: 2rem;
        text-align: center;
        color: $color-primary-2;
    }

    &__group {
        margin-bottom: 2rem;
    }

    &__forgot-pwd {
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-top: 2rem;
    }

    &__status {
        height: 1.5em;
        font-size: .7em;
        color: green;
        margin-bottom: 1em;

        & .invalid-code {
            color: red;
        }
    }

    &__statusbottom {
        height: 1.5em;
        font-size: .7em;
        color: green;
        padding-bottom: 2em;
        border-bottom: 2px solid $color-primary-1;
        margin-bottom: 3em;

        & .invalid-code {
            color: red;
        }
    }

    &__input {
        font-size: 1.5rem;
        font-family: inherit;
        color: inherit;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: rgba($color-white, .5);
        border: none;
        border-bottom: 3px solid transparent;
        width: 100%;
        display: block;
        margin: 0 0 0 0;
        transition: all .3s;

        &:focus {
            outline: none;
            box-shadow: 0 1rem 2rem rgba($color-black, .1);
            border-bottom: 3px solid $color-primary-1;

            &:invalid {
                border-bottom: 3px solid $color-orange-dark;
            }
        }

        &::-webkit-input-placeholder {
            color: $color-grey-1;
        }

        &:disabled {
            background-color: $color-grey-1;
            color: $color-grey-3;
            cursor: not-allowed;
            border: none;

            &::placeholder {
                color: $color-grey-3;
            }
        }

    }

    &__phone-status {
        height: 1.5em;
        font-size: .7em;
        color: red;

        & .hidden {
            display: none;
        }
    }

    &__label {
        margin: .7rem 0 0 2rem;
        padding: 0 0 0 0;
        color: $color-grey-1;
        font-size: 1.2rem;
        font-weight: 700;
        display: block;
        transition: all .3s;

        & .hidden {
            display: none;
        }
    }

    &__input:placeholder-shown+&__label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    select {
        font-size: 1.3rem;
        font-weight: 400;
        font-family: inherit;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // -ms-appearance: none;
        // appearance: none;
        outline: 0;
        box-shadow: none;
        border: 0 !important;
        background: rgba($color-white, .5);
        padding: .4rem 1rem;
        border-radius: 2px;
        background-image: none;
        margin-bottom: .5em;
    }

    fieldset {
        font-size: 1.5rem;
        font-family: inherit;
        font-weight: inherit;
        padding: 1rem 3rem;
        border: 1px solid $color-grey-1;
        border-radius: 5px;
        width: 90%
    }

    &__input-check {
        margin: 0 0;
    }

}