.breadcrumbs {
    font-size: 1.6rem;
    background-color: $color-white-1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.bc-home {
    &-anchor {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid transparent;
    }

    &-icon {
        height: 1.5rem;
        width: 2rem;
        fill: $color-primary-1;
    }
    
    &-text {
        font-size: 1rem;
        color: $color-primary-1;
    }
}