.breadcrumbs {
    padding: .5rem;
    white-space: nowrap;
    overflow-x: auto;

    .bc-separator {
        font-weight: 400;
    }

    .bc-leaf {
        font-weight: 400;
    }

    a:link,
    a:visited {
        text-decoration: none;
        color: $color-primary-1;
        font-weight: 400;
    }
}

.breadcrumbs-container {
    position: relative;
}