.social-share {
    box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: absolute;
    top: 4.5rem;
    right: 5px;
    display: block;
    z-index: 2;
    border-radius: .5rem;
    
    & ul {
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    &__options {
        list-style: none;
        padding: 10px 0;
        cursor: pointer;
    }

    &__icon {
        height: 2rem;
        width: 2rem;
        margin-left: 1rem;
        margin-top: .7rem;
        margin-bottom: .7rem;
        margin-right: 1rem;
        fill: $color-primary-1;
        align-items: center;
        justify-content: center;

        & .hidden {
            display: none;
        }
    }

    &__option {
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(large) {
            &:hover > svg *,
            &:hover {
                background-color: $color-primary-1;
                border-radius: .5rem;
                fill: #fff;
            }
        }
    }
}
    