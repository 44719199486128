.doc-page {
  width: 100%;
  max-width: 80rem;
  padding: 3rem;
  background-color: transparent;
  line-height: 2.3rem;
  font-weight: 400;

  @include respond(phone) {
    padding-top: 2.5rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    font-size: 1.2em;
    line-height: 1.5em;
  }

  & h1 {
    font-size: 2.5rem;
    line-height: 5rem;
    margin-bottom: 3rem;
    text-align: center;
    color: $color-primary-2;
  }

  & h2 {
    font-size: 2.2rem;
    line-height: 5rem;
    margin-bottom: 3rem;
    text-align: left;
    color: $color-primary-3;
  }

  & ul, ol {
    margin-left: 3rem;
    padding-left: 2rem;
    margin-bottom: 2rem;
  }

  a:link,
  a:visited {
    text-decoration: none;
    color: $color-primary-1;
  }

  a:hover {
    border-bottom: 2px solid $color-primary-1;
  }

  & .footer {

    margin-top: 3rem;

    & p {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $color-grey-2;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
}
