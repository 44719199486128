.state-and-district {
    display: flex;
    flex-direction: row;
    justify-content: left;

    &__state {
      flex-basis: 60%;
    }

    &__district {
      flex-basis: 40%;
    }
}