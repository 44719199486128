.action-link {
    font-size: 1.5rem;
    color: $color-primary-3;
    font-weight: 400;
    box-sizing: border-box;
    padding-bottom: 2px;
    border-bottom: 2px solid transparent;
    transition: all .2s;
    cursor: pointer;

    &:hover {
        color: $color-primary-1;
        border-bottom: 2px solid $color-primary-1;
    }
}