.left-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  font-size: 1.8rem;
  color: $color-logo-grey;
  background-image: linear-gradient(
    to bottom left,
    $color-grey-3,
    $color-primary-6
  );
  white-space: nowrap;

  & ul {
    margin-left: 1em;
  }
}

.tree-container {
  ul {
    list-style: none;
    line-height: 2em;

    li {
      position: relative;

      &::before {
        position: absolute;
        left: -10px;
        top: 0px;
        border-left: 1px solid gray;
        border-bottom: 1px solid gray;
        content: "";
        width: 8px;
        height: 1em;
      }

      &::after {
        position: absolute;
        left: -10px;
        bottom: 0px;
        border-left: 1px solid gray;
        content: "";
        width: 8px;
        height: 100%;
      }

      &:last-child::after {
        display: none;
      }
    }

    summary {
      cursor: pointer;
    }

    summary {
      &::marker {
        display: none;
      }

      &::-webkit-details-marker {
        display: none;
      }

      &::marker {
        content: "";
      }

      &::-webkit-details-marker {
        content: "";
      }

      &::before {
        color: white;
        position: absolute;
        left: -18px;
        top: 12px;
        content: "+";
        background: gray;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        z-index: 10;
        text-align: center;
        line-height: 12px;
      }
    }

    details[open] > summary::before {
      content: "-";
    }
  }

  left-nav-button {
    vertical-align: middle;
  }
}

.nav-selected {
  color: $color-black;
  background-color: $color-logo-grey;
}

ul.tree > li:after,
ul.tree > li:before {
  display: none;
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(phone) {
    padding-bottom: 10rem;
  }
  
}

.top-separator {
  width: 100%;
  border-top: 1px solid $color-grey-3;
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 3rem;
  margin-bottom: 2rem;
}

.footer-page-links {
  margin-top: 1.5rem;

  & a {
    font-size: 1.5rem;
  }

  & a:not(:last-child) {
    margin-right: 2rem;
  }

  & a:link,
  & a:visited {
    text-decoration: none;
    color: $color-logo-grey;
  }

  & a:hover {
    border-bottom: 2px solid $color-primary-1;
  }
  
}

.ddf-footer {
  color: $color-grey-2;
  font-size: 1.1rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}