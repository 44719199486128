.hidden {
    display: none;
}

.modal {
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    max-width: 50rem;
    background-color: $color-white-1;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
    z-index: 12;

    @include respond(phone) {
        width: 100%;
    }
}

.modal h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.modal p {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 11;
}

@media screen and (min-height: 900px) {
    .overlay {
        background-color: rgba(0, 0, 0, .6);
        backdrop-filter: blur(3px);
    }
}